.portfolio__container {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr);  */
  grid-template-columns: 45% 45%;
  /* width: 100%; */

  aspect-ratio: 1/1;
  gap: 1% 10%;
  width: 50%;
  margin: 0 auto;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  margin: 1.2rem;
  border-radius:2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem ;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }
  .portfolio__container > div {
    padding: 2rem;
    margin: 0 auto;
  }
.portfolio_content {
  padding: 1rem;
}

.portfolio__details > div h4 {
  font-size: 20px;
}

}


@media screen and (max-width: 600px) {
  .portfolio__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    width: 100% !important;
    margin: 0 1px 1px 0;
    padding: .1rem;
  }
  img {
    display: block;
    width: 19rem !important;
    object-fit: cover;
  }
  .portfolio__container > div {

    background: var( --color-bg-variant);
    border: 1px solid transparent;
    transition: var( --transition);


  }
  .eportfolio__container > div h3 {
    text-align: center;
    margin-bottom: .2rem;
    color: var(--color-primary);
    font-size: 20px;
  }
/*   .experience__container > div {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }  */
  .portfolio__details > div small {
    font-size: 1rem;
  }
}

