.experience__container {
/*   display: grid;
  grid-template-columns: repeat(1, 1fr); 
  gap: 2rem; */

  display: grid;
  grid-template-columns: 45% 45%;
  gap: 12%;
  width: 50%;
  margin: 0 auto;

}

.experience__container > div {
  background: var( --color-bg-variant);
  padding: 2.4rem 3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var( --transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var( --color-primary-variant);
  cursor: default;
} 

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  text-align: left;
}

.experience__details {
  display: flex;
  font-size: 14px;
  gap: 1rem;
}

.experience__content > div h4 {
  text-align: left;
  font-size: 10px;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary)
}








@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    padding: 2rem;
    margin: 0 auto;
  }
.experience__content {
  padding: 1rem;
}

.experience__details > div h4 {
  font-size: 20px;
}

}


@media screen and (max-width: 600px) {
  .experience__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    width: 100% !important;
    margin: 0 1px 1px 0;
    padding: .1rem;
  }
  .experience__container > div {

    background: var( --color-bg-variant);
    border: 1px solid transparent;
    transition: var( --transition);


  }
  .experience__container > div h3 {
    text-align: center;
    margin-bottom: .2rem;
    color: var(--color-primary);
    font-size: 16px;
  }
/*   .experience__container > div {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }  */
  .experience__details > div small {
    font-size: 1rem;
  }
}



