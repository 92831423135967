header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
  margin: 1rem auto;
}




.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__me {
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  /* overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

.header__me-image {
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 17rem);
  margin-top: 4rem;
  /* overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

.me {
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  /* overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}


@media screen and (max-width: 1024px) 
{
  header {
    height: 68vh;
  }
  .header__me-image {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 17rem);
    top: -6rem;
    /* margin-top: .02rem; */
    /* overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem; */
  }
}


@media only screen 

(max-device-width : 1024px) 
and (orientation : landscape) {
  
  header {
    height: 68vh;
  }
  .header__me-image {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 17rem);
    top: -6rem;
    /* margin-top: .02rem; */
    /* overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem; */
  }
}




@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }

  img {
    display: block;
    width: 24rem !important;
    object-fit: cover;
  }

  .header__me-image {
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 8rem);
    top: -6rem;
    /* margin-top: .02rem; */
    /* overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem; */
  }

}